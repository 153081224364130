import { Vue, Component, Prop } from 'vue-property-decorator';
import LineChart from '../../chartcomponents/linecomponent/LineComponent.vue';
import BarChart from '../../chartcomponents/barcomponent/BarComponent.vue';
import DoughnutChart from '../../chartcomponents/doughnutcomponent/DoughnutComponent.vue';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';

@Component({
  components: {
    'LineChart': LineChart,
    'DoughnutChart': DoughnutChart,
    'BarChart': BarChart,
  }
})
export default class DashboardPrintComponent extends Vue {

    private objScreenText: ScreenText = new ScreenText();
    tabs: any[] = JSON.parse(JSON.stringify(APP_CONST.TAB_SELECTION));

    @Prop() showPercentage!: any;
    @Prop() barstyles!: any;
    @Prop() doughnutStyle!: any;
    @Prop() slicedBarChartDataFormat!: any;
    @Prop() doughnutDataFormat!: any;
    @Prop() doughnutPercentageFormat!: any;
    @Prop() slicedLineChartDataFormat!: any;
    @Prop() styles!: any;
    @Prop() dataloaded!:any;
    @Prop() userDetails!:any;
    @Prop() selectedSiteId!:number;
    @Prop() attandancePercentage!:any;
    @Prop() selectedSite!:string;
    @Prop() endDates!:any;
    @Prop() selectedTab!:string;
    @Prop() TabIndex!:number;
    @Prop() lineChartMessage!:boolean;
    @Prop() barChartMessage!:boolean;
    @Prop() renderPrintKey!:any;
    @Prop() roleId!:number;
    @Prop() siteData!:any; 
    @Prop() hideAnnotation!: boolean;

    /* istanbul ignore next */
    formateDate(date: any) {
      if (!date) {
        return date;
      }
      return APP_UTILITIES.formatDate(date);
    }

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

}